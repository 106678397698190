.wrapper {
  padding: 16px;
  border-radius: 12px 16px;
  background-color: white;
  :global {
    .ant-input,
    .ant-input::placeholder {
      color: @primary-color;
    }

    thead > tr > th {
      color: @primary-color;
      font-weight: 600;
    }

    tbody > tr > td {
      color: @primary-color;
    }

    .ant-tag {
      padding: 4px 16px;
      border: 1px solid #c7d3dc;
      border-radius: 16px;
    }

    .ant-btn {
      border-radius: 7px;
      color: @primary-color;
    }

    .ant-btn-primary {
      color: @white-color;
    }

    .anticon-clear {
      color: @primary-color;
    }

    .ant-pagination {
      display: none;
    }
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .tableHeaderText {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    color: @primary-color;
  }
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;