.measurements {

  :global {
    .ant-btn {
      border-radius: 7px;
    }

    .ant-form-item {
      margin-bottom: 8px;
    }

    .ant-form-item-row {
      margin-bottom: 8px;
    }

    .ant-form-item-label > label {
      font-weight: 600;
      color: @primary-color;
      line-height: 22px;
      font-size: 14px;
    }

    .ant-col-24.ant-form-item-label {
      padding-bottom: 2px;
      line-height: 22px;
      font-size: 14px;
    }

    .ant-input {
      border-radius: 7px;
      color: @primary-color;
    }

    .ant-select-selector {
      border-radius: 7px !important;
      color: @primary-color;
    }
  }
}

.head {
  margin-bottom: 22px;
}

.title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: @primary-color;
}

.group {
  margin-bottom: 4px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 12px 16px;
  background-color: white;
  border-radius: 12px;

  .groupItem {
    margin-bottom: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: @primary-color;

    span:first-child {
      font-weight: 600;
      margin-right: 8px;
    }

    span:last-child {
      display: block;
      text-decoration-line: underline;
      text-decoration-style: dotted;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
}

.groupTitle {
  font-size: 16px;
  font-weight: 600;
  display: block;
  color: @primary-color;
}

.info {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: @primary-color;
}

.parcelTitleWrapper {
  display: flex;
  align-items: center;
  & > button {
    margin-left: 8px;
    padding: 4px;
    height: 28px;
    width: 28px;
    align-items: center;
    display: flex;
  }
}

.wrapper {
  display: flex;
  gap: 12px;
}

.buttons {
  display: flex;
  padding: 12px 16px;
  justify-content: end;
  background-color: white;
  border-radius: 12px;
  & > button {
    display: block;
  }
  & > button:first-child {
    margin-right: 12px;
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.deliveryList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.parcelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @primary-color;
  margin-bottom: 12px;
}

.columnHeader {
  padding: 12px 16px;
  background-color: white;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: @primary-color;
  margin-bottom: 4px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.addParcel {
  padding: 12px 8px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > span {
    margin-left: 12px;
    display: block;
    font-weight: 600;
    line-height: 22px;
    color: @primary-color;
  }
}

.columnsWrapper {
  display: flex;
  gap: 24px;
  & > div {
    width: 33%;
  }
}

.splitGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @primary-color;
}

.submitBtn {
  color: white !important;
}

.submitBtnWrapper {
  & > div {
    margin-bottom: 0 !important;
  }
  margin: 0 !important;
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;