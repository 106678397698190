.headerFilterWrapper {
  padding: 12px 16px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.headerLeftContent {
  display: flex;
  align-items: center;

  & > .headerTotal {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: @primary-color;
  }
}

.beginning-price {
  text-decoration: line-through;
  margin-right: 4px;
}

.finalPrice {
  text-decoration: underline dotted;
  cursor: pointer;
  text-underline-offset: auto;
  text-underline-position: from-font;
  font-weight: 600;
  display: inline-block;
}

.orderTaskMainContentWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .avatarWrapper {
    border-radius: 8px;
    height: 108px;
    width: 83px;
    padding: 4px
  }

  .avatar {
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }

  .orderTaskMainContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 12px;
  }

  .orderTaskHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .orderNumber {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    display: block;
    color: @primary-color;
  }

  .copyStyle {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .orderPrice {
    color: @primary-color;
    display: flex;
    & > span:first-child {
      font-weight: 600;
      margin-right: 4px;
    }
    line-height: 22px;
  }

  .orderSize {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
  }
}

.expandGroup {
  display: flex;
  align-items: start;
  color: @gray-color;
  & > button, a {
    display: flex;
    align-items: center;
    color: @gray-color;
    & > span:first-child {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-right: 4px;
    }
  }

  & > div {
    height: 10px;
    margin-right: 4px;
    margin-left: 4px;
    border-bottom: 1px solid #EFF1F2;
    flex-grow: 1;
  }
}

.postText {
  color: @primary-color;
  line-height: 22px;
  margin-bottom: 16px;
  display: block;
}

.sourceInfo {
  display: flex;
  justify-content: space-between;
  color: @primary-color;
  font-weight: 600;
  & > p > a {
     text-decoration: underline;
   }
}

.size {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: @primary-color;
}

.noSize {
  font-style: italic;
  font-size: 13px;
  line-height: 20px;
  color: @gray-color;
}

.subInfoWrapper {
  display: flex;
  align-items: center;
  height: 20px;
  & > a {
    display: block;
    font-size: 13px;
    line-height: 20px;
    text-decoration: underline;
    text-underline-offset: auto;
    text-underline-position: from-font;
    margin-right: 4px;
    margin-left: 4px;
  }

  & > span {
    display: block;
    font-size: 13px;
    line-height: 20px;
    color: @primary-color;
  }
}

.chipsWrapper {
  display: flex;
  gap: 16px;
  margin-left: 24px;
}

.chip {
  padding: 4px 12px;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;