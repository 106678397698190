.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  p {
    margin-bottom: 4px;
    color: @primary-color;
  }
}

.title {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 700;
  color: @primary-color;
  text-decoration: underline;
}

.name {
  a {
    overflow-wrap: break-word;
  }
}

.subInfo {
  display: block;
  margin-top: 2px;
  color: @primary-color;
}

.phone {
  text-decoration: underline dotted @primary-color;
  color: @primary-color;
  text-underline-position: from-font;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;